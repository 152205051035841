$(function(){
	
	var wh = $(window).height();
	var ww = $(window).width();

	// setTimeout(function() {
		$('[data-toggle="tooltip"]').tooltip()
		$('[data-toggle="popover"]').popover()
	// }, 1000)

	var popOverSettings = {
		placement: 'bottom',
		container: 'body',
		trigger: 'focus',
		html: true,
		selector: '[rel="popover"]'
	}
	
	$('body').popover(popOverSettings);

	$(document).on({
		mouseenter: function () {
			$(this).popover('show')
		},
		mouseover: function () {
			$(this).popover('show')
		},
		click: function() {
			$(this).popover('hide')
		},
		mouseleave: function() {
			$(this).popover('hide')
		},
		mouseout: function() {
			$(this).popover('hide')
		}
	}, ".dynamicPopover");

	
	$(document).on({
		mouseenter: function () {
			$(this).tooltip('show')
		},
		mouseover: function () {
			$(this).tooltip('show')
		},
		click: function() {
			$(this).tooltip('hide')
		},
		mouseleave: function() {
			$(this).tooltip('hide')
		},
		mouseout: function() {
			$(this).tooltip('hide')
		}
	}, ".dynamicTooltip");
	
});
  

